import React, { Fragment } from 'react'
import styled from 'styled-components'
import sunflowerImg from '../../images/Bachbloesems.jpg'
import { Title } from '../title/Title'
import { BloesemItem } from './BloesemItem'
import { BachbloesemsArray } from './bloesems'

const BackgroundImg = styled.img`
  height: 100vh;
  margin: auto;
  width: 100%;
  object-fit: cover;
  position: fixed;
  z-index: -1;
  object-position: center;
  @media (max-width: 700px) {
    width: 100vw;
  }
`
const BloesemWrapper = styled.div`
  height: 100%;
  width: 100%;
  overflow: hidden;
  @media (max-width: 700px) {
    width: 100vw;
  }
`

const Padding = styled.div`
  position: absolute;
  background-color: rgba(237, 237, 237, 0.5);
  height: 50vh;
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
`
const WidthWrapper = styled.div`
  width: 60vw;
  @media (max-width: 700px) {
    width: 85vw;
  }
`

const Background = styled.div`
  width: 100%;
  position: absolute;
  margin-top: 50vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: white;
  @media (max-width: 700px) {
    width: 100vw;
  }
`

const Info = styled.p`
  width: 60vw;
  @media (max-width: 700px) {
    width: 85vw;
  }
`

const BloesemCollection = styled.div`
  width: 60vw;
  height: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  background-color: white;
  padding-bottom: 100px;
  @media (max-width: 700px) {
    width: 85vw;
  }
`

const FlexDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media (max-width: 700px) {
    width: 100vw;
  }
`

const BloesemCWrapper = styled.div`
  width: 100%;
  background-color: white;
  display: grid;
  grid-template-columns: auto auto auto auto;
  justify-content: space-between;
  @media (max-width: 1350px) {
    grid-template-columns: auto auto auto;
  }
  @media (max-width: 1000px) {
    grid-template-columns: auto auto;
  }
`

const BloesemTitle = styled.h1`
  text-align: left;
  width: 100%;
  padding-bottom: 30px;
`

export default class Bachbloesems extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      hide: true
    }
  }
  renderBloesems () {
    return BachbloesemsArray.map(item => {
      return (
        <Fragment>
          <BloesemTitle>{item.title}</BloesemTitle>
          <BloesemCWrapper>
            {
              item.bloesems.map(bloesem => {
                return <BloesemItem onShowInfo={() => this.showBloesemInfo(bloesem)} key={bloesem.id} image={bloesem.img} item={bloesem} />
              })
            }
          </BloesemCWrapper>
        </Fragment>
      )
    })
  }
  renderExtraBloesems () {
    return BachbloesemsArray.slice(4).map(item => {
      return <BloesemItem onShowInfo={() => this.showBloesemInfo(item)} key={item.id} image={item.img} item={item} />
    })
  }
  render () {
    return (
      <BloesemWrapper>
        <BackgroundImg src={sunflowerImg} alt='bachbloesems' />
        <Padding>
          <WidthWrapper>
            <Title title='BACHBLOESEMS' />
          </WidthWrapper>
        </Padding>
        <Background>
          <Info>
            <br />
            <br />
            <b>Een eenvoudige, volledig natuurlijke methode</b>, ontdekt door de Engelse arts Dr. Edward Bach in de eerste helft van de vorige eeuw.
            <br />
            <br />
            Bach Bloemen Remedies kunnen je helpen wanneer je <b>overweldigd wordt door allerlei emoties</b> die het dagelijkse functioneren zwaar, lastig, moeilijk maken.
            <br />
            <br />
            Denk bijvoorbeeld aan twijfels, gebrek aan zelfvertrouwen, angsten, zorgen, verdriet, onzekerheid, moedeloosheid, zich zorgen maken, moeit hebben met het nemen van beslissingen, onvoldoende opkomen voor jezelf, …
            <br />
            <br />
            Die <b>aanhoudende psychische zorgen belasten je en putten je vitaliteit</b> uit.
            <br />
            Een juiste keuze van Bachbloesems kan ervoor zorgen dat <b>rust, vrede en harmonie</b> in de geest terugkeert. Bachbloesems worden niet voorgeschreven voor lichamelijke klachten. Ze zorgen er wel voor dat de levenskracht opnieuw vrij kan stromen, waardoor ook de <b>natuurlijke weerstand wordt hersteld</b>.
            <br />
            <br />
            Ze kunnen dus een waardevolle ondersteuning bieden in vele moeilijke levenssituaties, zoals stress, verlieservaringen, echtscheiding, burn-out, relatieproblemen, opvoedingsproblemen, rouw, lastige situaties op het werk, enz.
            <br />
            <br />
            De Bachbloemen-remedies worden bereid uit wilde bloemen en bloesems van struiken en bomen. Ze zijn <b>100% natuurlijk</b> en ze kunnen <b>zonder risico en zonder bijwerkingen</b> worden ingenomen, zowel door <b>volwassenen</b>, als door <b>kinderen</b> en <b>baby’s</b>. Kinderen reageren trouwens vaak zeer goed op de Bachbloesem-remedies waardoor deze een waardevolle ondersteuning kunnen bieden in de verschillende ontwikkelingsstadia of bij de verwerking van bepaalde trauma’s.
            <br />
            Er zijn <b>38 verschillende remedies</b> voor 38 verschillende gemoedstoestanden.
            <br />
            <br />
            Bachbloesems werken <b>niet verslavend</b>. Zij kunnen ter ondersteuning van andere geneesmiddelen, therapieën en behandelingen worden gebruikt, zonder de werking hiervan negatief te beïnvloeden. Wist u dat ze ook <b>heilzaam</b> zijn <b>voor dieren en planten</b>?
            <br />
            <br />
            Aan de hand van een <b>persoonlijk gesprek</b> worden de <b>passende remedies</b> aangereikt.
            <br />
            <br />
          </Info>
          <BloesemCollection>
            <FlexDiv>
              {this.renderBloesems()}
            </FlexDiv>
          </BloesemCollection>
        </Background>
      </BloesemWrapper>
    )
  }
}
