import React, { Fragment } from 'react'
import styled from 'styled-components'
import { NavHashLink as Link } from 'react-router-hash-link'

const CloseMenu = styled.div`
  top: 0;
  position: fixed;
  z-index: 101;
  margin: 10px;
  visibility: hidden;
  opacity: ${props => props.isMenuOpen ? 0 : 1};
  @media (max-width: 700px) {
    visibility: ${props => props.isMenuOpen ? 'hidden' : 'visible'};
  }
`
const OpenMenu = styled.div`
  top: 0;
  position: fixed;
  z-index: 101;
  margin: 10px;
  visibility: hidden;
  opacity: ${props => props.isMenuOpen ? 1 : 0};
  @media (max-width: 700px) {
    visibility: ${props => props.isMenuOpen ? 'visible' : 'hidden'};
  }
`

const MenuIcon = styled.img`
  height: 30px;
  width: 30px;
`

const Left = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  @media (max-width: 700px) {
    flex-direction: column;
    opacity: ${props => props.isMenuOpen ? 1 : 0};
    visibility: ${props => props.isMenuOpen ? 'visible' : 'hidden'};
    width: 100vw;
  }
`

const Navigation = styled.nav`
  z-index: 100;
  background-color: #ededed;
  position: fixed;
  top: 0;
  width: 100%;
  height: 8vh;
  box-shadow: 0px 0px 10px #6b6b6b;
  transition: height 200ms ease;
  overflow: hidden;
  @media (max-width: 700px) {
    height: ${props => props.isMenuOpen ? 24 : 8}vh;
    width: 100vw;
  }
`

const NavigationUl = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  display: flex;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: 21px;
  transition: opacity 200ms ease;
  @media (max-width: 700px) {
    justify-content: space-around;
    height: 24vh;
    width: 100vw;
  }
`

const NavigationLi = styled.li`
  height: 100%;
`

const Li = styled(Link)`
  color: #6b6b6b;
  text-decoration: none;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 15px;
  &:hover {
    cursor: pointer;
    background-color: green;
    color: white;
  }
`

export default class Header extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      isMenuOpen: false
    }
  }
  render () {
    const scroll = (el) => {
      window.scrollTo({ top: el.offsetTop + window.innerHeight - 160, left: 0, behavior: 'smooth' })
    }
    return (
      <Fragment>
        <Navigation isMenuOpen={this.state.isMenuOpen}>
          <NavigationUl>
            <Left isMenuOpen={this.state.isMenuOpen}>
              <NavigationLi onClick={() => this.setState({ isMenuOpen: false })}>
                <Li smooth to='/#Home'>Home</Li>
              </NavigationLi>
              <NavigationLi onClick={() => this.setState({ isMenuOpen: false })}>
                <Li scroll={el => scroll(el)} to='/#Aanbod'>Aanbod</Li>
              </NavigationLi>
              <NavigationLi onClick={() => this.setState({ isMenuOpen: false })}>
                <Li scroll={el => scroll(el)} to='/#OverMij'>Over mij</Li>
              </NavigationLi>
              <NavigationLi onClick={() => this.setState({ isMenuOpen: false })}>
                <Li scroll={el => scroll(el)} to='/#Tarieven'>Tarieven</Li>
              </NavigationLi>
              <NavigationLi onClick={() => this.setState({ isMenuOpen: false })}>
                <Li scroll={el => scroll(el)} to='/#Contact'>Contact</Li>
              </NavigationLi>
            </Left>
          </NavigationUl>
        </Navigation>
        <CloseMenu onClick={() => this.setState({ isMenuOpen: true })} isMenuOpen={this.state.isMenuOpen}><MenuIcon src='https://img.icons8.com/material-rounded/24/000000/menu.png' /></CloseMenu>
        <OpenMenu onClick={() => this.setState({ isMenuOpen: false })} isMenuOpen={this.state.isMenuOpen}><MenuIcon src='https://img.icons8.com/material-rounded/50/000000/multiply.png' /></OpenMenu>
      </Fragment>
    )
  }
}
