import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { Title } from '../title/Title'

const Offer = styled.div`
  height: 100%;
  width: 100%;
  margin: 0;
  margin-bottom: 0;
  background-color: rgba(237, 237, 237, 0.7);
  display: flex;
  flex-direction: column;
  align-items: center;
`

const NavOfferLinks = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
  flex-wrap: wrap;
  @media (max-width: 900px) {
    justify-content: space-around;
  }
`

const OfferWrapper = styled.div`
  width: 60%;
  @media (max-width: 900px) {
    width: 90%;
  }
`

const OfferLink = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 17vw;
  width: 17vw;
  margin: 5px;
  border-radius: 50%;
  background-color: #385903;
  border: 1px solid #ededed;
  box-shadow: 0px 0px 10px #6b6b6b;
  font-size: 28px;
  @media (max-width: 900px) {
    height: 20vw;
    width: 20vw;
    font-size: 18px;
    min-height: 135px;
    min-width: 135px;
    /* flex-direction: row; */
  }
  /* @media (max-width: 1200px) {
    font-size: 20px;
  } */
`

export default () => <Offer id='Aanbod'>
  <OfferWrapper>
    <Title title='Aanbod' />
    <NavOfferLinks>
      <OfferLink to='/Bachbloesems/' target='_top'>Bachbloesems</OfferLink>
      <OfferLink to='/Massages/' target='_top'>Massages</OfferLink>
      <OfferLink to='/WorkshopsEnLezingen/' target='_top'>Workshops en lezingen</OfferLink>
    </NavOfferLinks>
  </OfferWrapper>
  <br />
  <br />
  <br />
</Offer>
