import React from 'react'
import styled from 'styled-components'
import { Title } from '../title/Title'
import location from '../../images/Location.png'

const ContactFormulier = styled.div`
  width: 100%;
  padding-bottom: 40px;
`

const Adress = styled.div`
  width: calc(100% - 20px);
  background-color: #6b6b6b;
  color: white;
  padding: 10px;
  border-radius: 5px;
  opacity: 0.7;
`

const MailWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const MailLink = styled.a`
  padding: 5px;
  margin-left: 5px;
  background-color: #385903;
`

const LocationImg = styled.img`
  height: 50vh;
  padding-top: 20px;
  width: 100%;
  object-fit: cover;
`

export default class Contact extends React.Component {
  render () {
    return (
      <ContactFormulier id='Contact'>
        <Title title='Contact' />
        Enkel op afspraak.
        <Adress>
          <p>Platanendreef 36</p>
          <p>2950 KAPELLEN (ANTWERPEN)</p>
          <p>Tel: 0479 65 66 15</p>
          <MailWrapper>
            Mail: <MailLink href='mailto:info@arsvitae.be' target='_top'>info@arsvitae.be</MailLink>
          </MailWrapper>
        </Adress>
        <LocationImg src={location} alt='location' />
        {/* <Label>Naam</Label>
        <Input />
        <Label>Telefoon</Label>
        <Input />
        <Label>email</Label>
        <Input />
        <Label>Datum van afspraak</Label>
        <Input />
        <Label>Opmerkingen</Label>
        <TextArea />
        <SendButton>Verzenden</SendButton> */}
      </ContactFormulier>
    )
  }
}
