import React from 'react'
import styled from 'styled-components'
import './quote.css'

const Author = styled.p`
  margin: 0;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
`

const QuotePadding = styled.div`
  padding: 50px 0;
  width: 60%;
  @media (max-width: 700px) {
    width: 80%;
  }
`

export default class Quote extends React.Component {
  render () {
    return (
      <QuotePadding>
        <p class='quote'>Zolang de ziel, het lichaam en de geest in harmonie zijn, zijn we immuun voor ziekte</p>
        <Author>Dr. Edward Bach</Author>
      </QuotePadding>
    )
  }
}
