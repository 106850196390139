import React from 'react'
import styled from 'styled-components'
import sunflowerImg from '../../images/Stoelmassage.jpg'
import { Title } from '../title/Title'
import { MassageItem } from './MassageItem'
import { MassageArray } from './MassageArray'

const BackgroundImg = styled.img`
  height: 100vh;
  width: 100%;
  object-fit: cover;
  position: fixed;
  z-index: -1;
`
const MassageWrapper = styled.div`
  height: 100%;
  width: 100%;
`

const Padding = styled.div`
  position: absolute;
  background-color: rgba(237, 237, 237, 0.5);
  height: 50vh;
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
`
const WidthWrapper = styled.div`
  width: 85vw;
`

const Background = styled.div`
  width: 100%;
  position: absolute;
  margin-top: 50vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: white;
`

const Info = styled.p`
  width: 85vw;
  margin-bottom: 50px;
`

const MassageCollection = styled.div`
  width: 85%;
  height: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  background-color: white;
  padding-bottom: 150px;
`

const MassageCWrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: white;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
`

export default class Massages extends React.Component {
  closeMassageInfo (item) {

  }
  showMassageInfo (item) {

  }
  renderMassages () {
    return MassageArray.map(item => {
      return <MassageItem onShowInfo={() => this.showMassageInfo(item)} key={item.id} image={item.img} item={item} />
    })
  }
  render () {
    return (
      <MassageWrapper>
        <BackgroundImg src={sunflowerImg} alt='Massages' />
        <Padding>
          <WidthWrapper>
            <Title title='MASSAGES' />
          </WidthWrapper>
        </Padding>
        <Background>
          <Info>
            <br />
            <br />
            Massage is zeer heilzaam bij allerhande klachten. Het wordt ingezet bij de behandeling van <b>acute en chronische pijn, bewegingsklachten, stress, slaapproblemen, hoofdpijn, … </b>
            <br />
            <br />
            Ik bied verschillende soorten massage aan. Klik voor meer uitleg even door op de verschillende massagevormen.
            <br />
            <br />
            Dieptemassage en tuina/acupressuur worden vaak gecombineerd in een behandeling, afhankelijk van de klachten.
            <br />
            <br />
            Een ‘consultatie’ duurt een uur - de eigenlijke massage ongeveer 45 minuten.
          </Info>
          <MassageCollection>
            <MassageCWrapper>
              {this.renderMassages()}
            </MassageCWrapper>
          </MassageCollection>
        </Background>
      </MassageWrapper>
    )
  }
}
