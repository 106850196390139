import React from 'react'
import styled from 'styled-components'

const TitleWrapper = styled.h1`
  text-transform: uppercase;
  width: 100%;
  font-size: 32px;
  padding-top: 30px;
  @media (max-width: 700px) {
    font-size: 24px;
  }
`

export const Title = ({ title }) => {
  return (
    <TitleWrapper>{title}</TitleWrapper>
  )
}
