import Agrimony from '../../images/bloesems/Agrimony.jpg'
import Cerato from '../../images/bloesems/Cerato.jpg'
import Chicory from '../../images/bloesems/Chicory.jpg'
import Clematis from '../../images/bloesems/Clematis.jpg'
import Heather from '../../images/bloesems/Heather.jpg'
import Honeysuckle from '../../images/bloesems/Honeysuckle.jpg'
import Impatiens from '../../images/bloesems/Impatiens.jpg'
import Mimulus from '../../images/bloesems/Mimulus.jpg'
import Olive from '../../images/bloesems/Olive.jpg'

import Aspen from '../../images/bloesems/ASPEN.JPG'
import Beech from '../../images/bloesems/BEECH.JPG'
import Centaury from '../../images/bloesems/CENTAURY.JPG'
import CherryPlum from '../../images/bloesems/CHERRYPLUM.JPG'
import Chestnut from '../../images/bloesems/CHESTNUTBUD.JPG'
import Crab from '../../images/bloesems/CRABAPPLE.JPG'
import Elm from '../../images/bloesems/ELM.JPG'
import Gentian from '../../images/bloesems/GENTIAN.JPG'
import Gorse from '../../images/bloesems/GORSE.JPG'
import Holly from '../../images/bloesems/HOLLY.JPG'
import Hornbeam from '../../images/bloesems/HORNBEAM.JPG'
import Larch from '../../images/bloesems/LARCH.JPG'
import Mustard from '../../images/bloesems/MUSTARD.JPG'
import Oak from '../../images/bloesems/OAK.JPG'
import Pine from '../../images/bloesems/PINE.JPG'
import Redchestnut from '../../images/bloesems/REDCHESTNUT.JPG'
import Rockrose from '../../images/bloesems/ROCKROSE.JPG'
import Rockwater from '../../images/bloesems/ROCKWATER.JPG'
import Scleranthus from '../../images/bloesems/SCLERANTHUS.JPG'
import Starofbethlehem from '../../images/bloesems/STAROFBETHLEHEM.JPG'
import Vervain from '../../images/bloesems/VERVAIN.JPG'
import Vine from '../../images/bloesems/VINE.JPG'
import Walnut from '../../images/bloesems/WALNUT.JPG'
import Waterviolet from '../../images/bloesems/WATERVIOLET.JPG'
import WhiteChestnut from '../../images/bloesems/WHITECHESTNUT.JPG'
import WildOat from '../../images/bloesems/WILD_OAT.JPG'
import WildRose from '../../images/bloesems/WILDROSE.JPG'
import Willow from '../../images/bloesems/WILLOW.JPG'

export const BachbloesemsArray = [
  {
    title: 'Angst',
    bloesems: [
      {
        id: 1,
        name: 'Rock Rose',
        longName: 'Rock Rose – zonneroosje',
        img: Rockrose,
        info: 'paniek, extreme angst, te hard schrikken van gewone dingen, nachtmerries.'
      },
      {
        id: 2,
        name: 'Mimulus',
        longName: 'Mimulus – maskerbloem',
        img: Mimulus,
        info: 'vrees voor iets bekends, angst met een aanwijsbare oorzaak, verlegenheid, schuchterheid'
      },
      {
        id: 3,
        name: 'Aspen',
        longName: 'Aspen – ratelpopulier',
        img: Aspen,
        info: 'plotse, onverklaarbare angst, angstige voorgevoelens, angst voor naderend onheil, diepe ongerustheid'
      },
      {
        id: 4,
        name: 'cherry plum',
        longName: 'Kerspruim – cherry plum',
        img: CherryPlum,
        info: 'angst om de controle of het verstand te verliezen, radeloosheid, vertwijfeling, angst om uzelf of anderen iets verschrikkelijks aan te doen, angst om domme dingen te doen'
      },
      {
        id: 5,
        name: 'Red chestnut',
        longName: 'Red chestnut – rode kastanje',
        img: Redchestnut,
        info: 'angstige bezorgdheid om anderen, direct het ergste vrezen als iets anders loopt – doemscenario’s, bang dat er iets misloopt met geliefden'
      }
    ]
  },
  {
    title: 'Onzekerheid',
    bloesems: [
      {
        id: 6,
        name: 'Cerato',
        longName: 'Cerato – loodkruid',
        img: Cerato,
        info: 'twijfelen aan uw eigen aanvoelen en uw eigen weten en bekwaamheid, zichzelf wantrouwen en daardoor steeds raad vragen aan anderen, en zo misleid worden'
      },
      {
        id: 7,
        name: 'Scleranthus',
        longName: 'Scleranthus – hardbloem',
        img: Scleranthus,
        info: 'onzekerheid, besluiteloosheid, onevenwichtigheid, gespannen door onvermogen om te kiezen tussen 2 mogelijkheden, heel lang aarzelen, sterk wisselende stemmingen'
      },
      {
        id: 8,
        name: 'Gentian',
        longName: 'Gentian – gentiaan',
        img: Gentian,
        info: 'snel ontmoedigd, teleurgesteld, neerslachtig door bekende oorzaak, twijfel aan de goede afloop, de ontmoediging is vaak buiten verhouding t.o.v. de aanleiding'
      },
      {
        id: 9,
        name: 'Gorse',
        longName: 'Gorse – gaspeldoorn',
        img: Gorse,
        info: 'in alle chronische situaties, geen hoop en/of vertrouwen in mogelijkheden tot verdere hulp, gevoel van "je moet er leren mee leven", “er is niets dat of niemand die mij nog kan helpen”'
      },
      {
        id: 10,
        name: 'Hornbeam',
        longName: 'Hornbeam – haagbeuk',
        img: Hornbeam,
        info: 'ergens tegenop zien, er niet aan kunnen beginnen, maandagmorgengevoel, moeheid die tussen de oren zit - eens bezig, verdwijnt de moeheid vaak en wordt de taak volbracht, ook ervaren van een sleur, eentonigheid'
      },
      {
        id: 11,
        name: 'Wild oat',
        longName: 'Wild oat – ruwe dravik',
        img: WildOat,
        info: 'gebrek aan vervulling, ontevredenheid met zijn taak in het leven, kent zijn richting/roeping niet, weet niet wat hij echt wil doen'
      }
    ]
  },
  {
    title: 'Onvoldoende interesse in het heden',
    bloesems: [
      {
        id: 12,
        name: 'Clematis',
        longName: 'Clematis – bosrank',
        img: Clematis,
        info: 'dromerig, onoplettend, verstrooid, zich terugtrekken in de eigen fantasiewereld/droomwereld, weinig belangstelling voor gebeurtenissen in de eigen omgeving, flauwvallen/bewusteloosheid'
      },
      {
        id: 13,
        name: 'Honeysuckle',
        longName: 'Honeysuckle – tuinkamperfoelie',
        img: Honeysuckle,
        info: 'stilstaan bij gedachten, gevoelens uit het verleden, heimwee, nostalgie naar vroegere tijden, pijnlijke herinneringen niet kunnen loslaten'
      },
      {
        id: 14,
        name: 'Wild rose – hondsroos',
        longName: 'Wild rose – hondsroos',
        img: WildRose,
        info: 'berusting, lusteloosheid, apathie, levenloos'
      },
      {
        id: 15,
        name: 'Olive',
        longName: 'Olive – olijf',
        img: Olive,
        info: 'geestelijk en/of lichamelijk uitgeput na langdurige belasting, innerlijke leegte, alles is een zware last, leeg, op, uitgeput'
      },
      {
        id: 16,
        name: 'White chestnut',
        longName: 'White chestnut – paardekastanje',
        img: WhiteChestnut,
        info: 'aanhoudend ongewenste gedachten, piekeren, dubben, discussies in gedachten'
      },
      {
        id: 17,
        name: 'Mustard',
        longName: 'Mustard – heerik',
        img: Mustard,
        info: 'diepe neerslachtigheid die komt en gaat zonder bekende oorzaak, je somberheid, zwaarmoedigheid, je word erdoor overvallen, zich afgesneden voelen, de verbinding met uw plan/hoger zelf is volledig verbroken'
      },
      {
        id: 18,
        name: 'Chestnut but',
        longName: 'Chestnut but – knop van de paardekastanje',
        img: Chestnut,
        info: 'niets leren uit ondervinding door gebrekkige opmerkzaamheid, niet stilstaan bij ervaringen, steeds dezelfde fouten maken, omdat ervaringen niet verwerkt wordt, leven met een innerlijke haast'
      }
    ]
  },
  {
    title: 'Eenzaamheid',
    bloesems: [
      {
        id: 19,
        name: 'Waterviolet',
        longName: 'Waterviolet – waterviolier',
        img: Waterviolet,
        info: 'trots, gereserveerd, onbereikbaar, je krijgt er geen hoogte van, zich uit de  hoogte gedragen, superioriteitsgevoel, schijnstabiliteit, controle, is graag alleen'
      },
      {
        id: 20,
        name: 'Impatiens',
        longName: 'Impatiens – reuzenbalsemien',
        img: Impatiens,
        info: 'ongeduld, stress, prikkelbaarheid, irritatie bij traagheid, snel in denken en doen, werkt liefst alleen'
      },
      {
        id: 21,
        name: 'Heather',
        longName: 'Heather – struikheide',
        img: Heather,
        info: 'grote behoefte aan aandacht, egocentrisch, wil in het middelpunt staan, bezorgd om zichzelf (alleen), kan niet alleen zijn, praat constant over zichzelf, ze staan onder een hoge druk, hoge spanning'
      }
    ]
  },
  {
    title: 'Overgevoelig voor ideeën en invloeden van anderen',
    bloesems: [
      {
        id: 22,
        name: 'Agrimony',
        longName: 'Agrimony – agrimonie',
        img: Agrimony,
        info: 'schijnbaar opgewekt, lijken zorgeloos, geheimgehouden zorgen voor anderen, gevoelig voor spanning, kunnen moeilijk inslapen of stilvallen, zich nodeloos op voorhand overmatig zorgen maken'
      },
      {
        id: 23,
        name: 'Centaury',
        longName: 'Centaury – duizendguldenkruid',
        img: Centaury,
        info: 'te grote dienstbaarheid, zichzelf opofferen ten voordele van de ander: zet zichzelf op de 2de plaats, gemakkelijk uitgebuit, kan niet neen zeggen, zwakke wil voor zichzelf, te weinig individualiteit, laat zich heel gemakkelijk beïnvloeden'
      },
      {
        id: 24,
        name: 'Walnut',
        longName: 'Walnut – walnoot',
        img: Walnut,
        info: 'beïnvloedbaar door sterke invloeden of zienswijzen van anderen, weten wat ze willen, maar worden omver geblazen, moeite met veranderingen en met overgang naar nieuwe levenssituaties of nieuwe levensfase, verbreken van oude banden en gewoonten'
      },
      {
        id: 25,
        name: 'Holly',
        longName: 'Holly – hulst',
        img: Holly,
        info: 'haat, nijd, agressie, jaloersheid, achterdocht, wantrouwen, inwendig lijden zonder klaar te zien in de oorzaak, de ander, dan IK OOK'
      }
    ]
  },
  {
    title: 'Wanhoop',
    bloesems: [
      {
        id: 26,
        name: 'Larch',
        longName: 'Larch – lariks',
        img: Larch,
        info: 'gebrek aan vertrouwen in het eigen kunnen, minderwaardig, ondanks bekwaamheid, gebrek aan zelfvertrouwen, verwacht te falen'
      },
      {
        id: 27,
        name: 'Pine',
        longName: 'Pine – den',
        img: Pine,
        info: 'zelfverwijt, schuldgevoel, neemt ook schuld van anderen op zich, tekortschieten, niet goed genoeg'
      },
      {
        id: 28,
        name: 'Elm',
        longName: 'Elm - veldiep',
        img: Elm,
        info: 'tijdelijk gevoel van overbelasting, tijdelijke uitputting door perfectionisme, tijdelijk overvraagd door verantwoordelijkheden, tijdelijk twijfelen aan eigen capaciteiten'
      },
      {
        id: 29,
        name: 'Sweet chestnut',
        longName: 'Sweet chestnut – tamme kastanje',
        img: Chestnut,
        info: 'uiterlijke, geestelijke, schrikbarende wanhoop, zielsangst, ondraaglijke smart, moederziel alleen, totaal verloren'
      },
      {
        id: 30,
        name: 'Star of bethlehem',
        longName: 'Star of bethlehem',
        img: Starofbethlehem,
        info: 'geestelijke of lichamelijke schok, trauma of nawerking ervan, blokkade, ontroostbaar verdriet na pijnlijk gebeuren'
      },
      {
        id: 31,
        name: 'Willow',
        longName: 'Willow – wilg',
        img: Willow,
        info: 'wrok, verbittering, afgunst, gevoel onrechtvaardig behandeld te worden, ik niet, dan de ander ook niet'
      },
      {
        id: 32,
        name: 'Oak',
        longName: 'Oak – eik',
        img: Oak,
        info: 'chronisch vermoeid, doch onophoudelijk inspanningen, doorvechten vanuit plichtsgevoel, ‘moeten’ op alle terreinen van zijn leven, wilskracht, doorzettingsvermogen, rots in de branding'
      },
      {
        id: 33,
        name: 'Crab apple',
        longName: 'Crab apple – appel',
        img: Crab,
        info: 'gefixeerd op details en geheel uit het oog verliezen, perfectionisme - nauwkeurige voorstelling van hoe iets moet zijn, walging, afkeer, schaamte voor aspecten van jezelf of voor wat je denkt, zegt, smetvrees, snel vies, idee fixe'
      }
    ]
  },
  {
    title: 'Overbezorgdheid voor welzijn van anderen',
    bloesems: [
      {
        id: 34,
        name: 'Chicory',
        longName: 'Chicory – cichorei',
        img: Chicory,
        info: 'zelfmedelijden, bemoederen, maakt afhankelijk, bezitterige levenshouding, zorg voor anderen om respect, waardering en sympathie te krijgen'
      },
      {
        id: 35,
        name: 'Vervain',
        longName: 'Vervain – ijzerhard',
        img: Vervain,
        info: 'overenthousiast, fanatiek, principes, gespannenheid, bewogen door onrecht, door onrechtvaardigheid, wil anderen overtuigen'
      },
      {
        id: 36,
        name: 'Vine',
        longName: 'Vine – wijnstok',
        img: Vine,
        info: 'overheersend en onbuigzaam, eer- en heerszuchtig, meedogenloos, autoritaire macht'
      },
      {
        id: 37,
        name: 'Beech',
        longName: 'Beech – beuk',
        img: Beech,
        info: 'onverdraagzaam, innerlijk gespannen, zucht tot bekritiseren, arrogantie, oordelen vellen en veroordelen'
      },
      {
        id: 38,
        name: 'Rock water',
        longName: 'Rock water – bronwater',
        img: Rockwater,
        info: 'zelfverloochening, martelaarschap, martelaar voor eigen ideaal of leefvorm, wil in stilte een voorbeeld zijn, ascese en onthechting'
      }
    ]
  }
]
