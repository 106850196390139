import React from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import Home from '../components/home/Home'
import Header from '../components/Header'
import Footer from '../components/Footer'
import styled from 'styled-components'
import Bachbloesems from '../components/bachbloesems/Bachbloesems'
import Massages from '../components/massages/Massages'
import WorkshopsEnLezingen from '../components/WorkshopsEnLezingen/WorkshopsEnLezingen'

const App = styled.div`
  height: 100%;
  width: 100%;
`

export const AppRouter = () => {
  return (
    <Router>
      <App>
        <Header />
        <Route path='/' exact component={Home} />
        <Route path='/bachbloesems' exact component={Bachbloesems} />
        <Route path='/massages' exact component={Massages} />
        <Route path='/WorkshopsEnLezingen' exact component={WorkshopsEnLezingen} />
        <Footer />
      </App>
    </Router>
  )
}
