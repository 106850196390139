import React from 'react'
import styled from 'styled-components'
import Winter from '../../images/Winter.jpg'
import Zomer from '../../images/Zomer.jpg'
import Lente from '../../images/Lente.jpg'
import Herfst from '../../images/Herfst.jpg'
import Quote from './Quote'
import Offer from './Offer'
import Prices from './Prices'
import Contact from '../Contact/Contact'
import { AboutMe } from './AboutMe'

const HomeWrapper = styled.div`
  position: relative;
`

const HomeImg = styled.img`
  height: 100vh;
  width: 100%;
  position: fixed;
  z-index: -1;
  object-fit: cover;
  @media (max-width: 700px) {
    width: 100vw;
  }
`

const Overlay = styled.div`
  position: absolute;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 700px) {
    width: 100vw;
  }
`

const TextWrapper = styled.div`
  width: 60%;
  padding-bottom: 50px;
  @media (max-width: 700px) {
    width: 90%;
  }
`

const Background = styled.div`
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 0px 20px #6b6b6b;
  border-top: 2px solid #385903;
  border-bottom: 2px solid #385903;
  @media (max-width: 700px) {
    width: 100vw;
  }
`

const MarginBox = styled.div`
  width: 100%;
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 700px) {
    width: 100vw;
  }
`

const LogoCircle = styled.div`
  height: 35vh;
  width: 35vh;
  border-radius: 50%;
  background-color:rgba(237, 237, 237, 0.7);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 20px #6b6b6b;
  border: 1px solid #385903;
`

const LogoTitle = styled.h1`
  margin: 0;
  padding: 0;
  font-size: 54px;
  text-align: center;
  @media (max-width: 700px) {
    font-size: 35px;
  }
`

const LogoText = styled.p`
  margin: 0;
  font-size: 32px;
  text-align: center;
  @media (max-width: 700px) {
    font-size: 24px;
  }
`

const ListContainer = styled.div`
  width: 500px;
  margin-top: 30px;
  max-width: 100%;
`

const ListItem1 = styled.div`
  width: 20%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 15px;
`
const ListItem2 = styled.div`
  width: 33%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 15px;
  `
const ListItem3 = styled.div`
  width: 41%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 15px;
`
const ListItem4 = styled.div`
  width: 78%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 30px;
`

const ArsVitae = styled.h1`
  font-size: 50px;
  background-color: #385903;
  color: white;
  text-align: center;
  @media (max-width: 700px) {
    font-size: 30px;
  }
`

const ArsVitaeWrapper = styled.div`
  background-color: #385903;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px;
`

const Of = styled.p`
  color: white;
  text-align: center;
  padding: 20px;
  font-size: 20px;
`

const Subtitle = styled.h1`
  width: 100%;
  text-align: center;
  margin-bottom: 50px;
  @media (max-width: 700px) {
    font-size: 20px;
  }
`

export default class Home extends React.Component {
  render () {
    const getImg = () => {
      const newDate = new Date()
      switch (newDate.getMonth()) {
        case 0:
        case 1:
        case 11:
          return Winter
        case 2:
        case 3:
        case 4:
          return Lente
        case 5:
        case 6:
        case 7:
          return Zomer
        case 8:
        case 9:
        case 10:
          return Herfst
        default:
          return Winter
      }
    }
    return (
      <HomeWrapper id='Home'>
        <HomeImg src={getImg()} alt='forest' />
        <MarginBox>
          <LogoCircle>
            <LogoTitle>Ars Vitae</LogoTitle>
            <LogoText>Chris Nelen</LogoText>
          </LogoCircle>
        </MarginBox>
        <Overlay>
          <Background>
            <Quote />
            <TextWrapper>
              <ArsVitaeWrapper>
                <ArsVitae><i>Ars vitae</i></ArsVitae>
                <Of>of</Of>
                <ArsVitae><i>'Levenskunst'</i></ArsVitae>
              </ArsVitaeWrapper>
              <Subtitle>Praktijk voor natuurlijke gezondheidszorg</Subtitle>
              <i>
                Voel je je niet lekker in je vel?
                <br />
                Ga je gebukt onder stress?
                <br />
                Heb je het gevoel helemaal 'vast' te zitten?
              </i>
              <ListContainer>
                <ListItem1><b>STRESS?</b></ListItem1>
                <ListItem2><b>BURN-OUT?</b></ListItem2>
                <ListItem3><b>BORE-OUT?</b></ListItem3>
                <ListItem4><b>LICHAMELIJKE KLACHTEN?</b></ListItem4>
              </ListContainer>
              Soms loopt het leven niet helemaal zoals je zou willen. Je hebt zorgen, verdriet, pijn. Je bent teleurgesteld in de gang van zaken, je lichaam wil niet meer mee ...
              <br />
              <br />
              Als gezondheidsbegeleider kijk ik vooral naar de ‘totale’ mens, d.w.z. de samenhang tussen lichaam, gevoelens en geest.
              <br />
              <br />
              Ik bied je een luisterend oor en ga samen met jou op zoek naar manieren om je gevoel van welzijn en je levensvreugde te verhogen en het evenwicht in jezelf terug te vinden.
              <br />
              <br />
              Mijn grote inspiratiebronnen zijn Dr. Edward Bach, die de Bach Bloemen Remedies ontdekte en de Traditionele Chinese Geneeskunde.
              <br />
              <br />
              Wil je graag meer informatie? Blader dan door de website of neem vrijblijvend contact met me op.
            </TextWrapper>
          </Background>
          {/* <NavLink to='Aanbod' /> */}
          <Offer />
          <Background>
            <TextWrapper>
              <AboutMe />
            </TextWrapper>
          </Background>
          <Prices />
          <Background>
            <TextWrapper>
              <Contact />
            </TextWrapper>
          </Background>
        </Overlay>
      </HomeWrapper>
    )
  }
}
