import React from 'react'
import styled from 'styled-components'
import facebook from '../images/facebook.png'
import linkedIn from '../images/linkedin.png'

const FooterContainer = styled.div`
  width: 100%;
  height: 50px;
  background-color: #595959;
  position: fixed;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  bottom: 0;
  @media (max-width: 700px) {
    width: 100vw;
  }
`

const Facebook = styled.a`
  padding-left: 20px;
`

const FbIcon = styled.img`
padding-top: 10px;
  height: 30px;
  width: 30px;
`

const Number = styled.div`
  color: white;
  padding: 15px 20px 0 0;
  font-size: 18px;
`

export default class Footer extends React.Component {
  render () {
    return (
      <FooterContainer>
        {/* <Padding /> */}
        <div>
          <Facebook href='https://www.facebook.com/arsvitae/' target='_blank'><FbIcon src={facebook} alt='facebook' /></Facebook>
          <Facebook href='https://be.linkedin.com/in/chris-nelen-54695213?trk=people-guest_profile-result-card_result-card_full-click' target='_blank'><FbIcon src={linkedIn} alt='linkedin' /></Facebook>
        </div>
        <Number>0479 65 66 15</Number>
      </FooterContainer>
    )
  }
}
