import Dieptemassage from '../../images/Dieptemassage.jpg'
import Tuina from '../../images/Tuina.jpg'
import Qigong from '../../images/Qigong.jpg'
import Stoelmassage from '../../images/Stoelmassage.jpg'
import React from 'react'

export const MassageArray = [
  {
    id: 1,
    name: 'Dieptemassage',
    img: Dieptemassage,
    info: <div>Dit is een vorm van massage waarbij diepe druk wordt gegeven, dwars op de vezelrichting van de spier. Door de diepe, dwarse fricties worden de spiergranulomen losgemaakt en worden bindweefselverklevingen opgeheven. De doorbloeding van de spier verbetert, afvalstoffen die in de spieren opgeslagen liggen worden verwijderd. Diepe druk, bijvoorbeeld naast de wervelkolom, stimuleert tevens de zenuwbanen. Dieptemassage geeft een diep, ontspannen gevoel. De massage is ideaal om spieren en spiergroepen los te maken. <br /><br /> Deze vorm van massage is aangewezen om <b>chronische en acute bewegings- en pijnklachten te verlichten zoals rugpijn, nekklachten, schouderproblemen, kniepijn, hoofdpijn…</b></div>
  },
  {
    id: 2,
    name: 'Tuina / acupressuur',
    img: Tuina,
    info: <div>Tuina wordt meestal vertaald als “Chinese massage en manuele therapie”. In de traditionele Chinese geneeskunde is Tuina één van de vijf hoofdpijlers naast acupunctuur, kruidengeneeskunde, voeding en medische Qigong.
      <br />
      <br />
      Enerzijds worden bepaalde spieren en lichaamszones gemasseerd met het oog op een betere doorbloeding, het opheffen van blokkades en het verlichten van pijn. Anderzijds worden ook de meridianen (energiebanen), acupunctuurpunten en triggerpunten gemasseerd en gestimuleerd, met de bedoeling om stagnaties op te heffen, de energiedoorstroming te verbeteren en de werking van de organen te stimuleren en te ondersteunen. De meridianen zijn immers verspreid over het ganse lichaam om alle inwendige organen met elkaar te verbinden.
      <br />
      <br />
      Soms wordt er – in overleg - ook gebruik gemaakt van andere technieken zoals cupping, moxa (of moxalamp) en guasha.
      <br />
      <br />
      Deze vorm van massage is aangewezen om <b>chronische en acute bewegings- en pijnklachten te verlichten zoals rugpijn, nekklachten, aandoeningen van bovenste of onderste ledematen, …</b>
      <br />
      <b>Ook voor andere klachten zoals stress, hoofdpijn, menstruatiepijn, verteringsproblemen, slapeloosheid, duizeligheid, emotionele spanningen, …</b>
      </div>
  },
  {
    id: 3,
    name: 'Qigong-behandeling',
    img: Qigong,
    info: <div>
      Dit is een volledig energetische behandeling, je zou het ook een ‘healing’ kunnen noemen.
      <br />
      <br />
      Het doel van de massage is het opheffen van blokkades en stagnaties en het harmoniseren van alle energieën in het lichaam.
      <br />
      <br />
      Die behandeling wordt gegeven op de kleding. het lichaam wordt deels op een zachte wijze aangeraakt en deels op afstand behandeld.
      <br />
      <br />
      Het is een weldadige en zeer ontspannende behandeling.
      <br />
      <br />
      Medische qigong is aangewezen bij <b> emotionele spanningen, stress, vermoeidheid, onrust, …</b>
    </div>
  },
  {
    id: 5,
    name: 'Stoelmassage',
    img: Stoelmassage,
    info: <div>
      Bij deze massage neemt u plaats op een massagestoel voor de massage van nek, schouders en rug. Meestal wordt de massage op de kleding gegeven, maar massage met olie is ook mogelijk.
      <br />
      <br />
      Deze massagevorm is zeer effectief bij <b>verlichten van stress, ontspannen van en losmaken van nek-, schouder en rugspieren. </b> Een weldaad voor mensen die veel zittend werk verrichten of tijdens het werk steeds dezelfde houding aannemen, en daardoor vaak verkrampen.
      <br />
      <br />
      Dit type van massage wordt dan ook vaak op verplaatsing aangeboden, bijvoorbeeld op de werkvloer zelf … Ik heb ervaring met stoelmassages in bedrijven, in dienstencentra, op beurzen, …
      <br />
      <br />
      Een stoelmassage duurt ongeveer 20 minuten.
      <br />
      <br />
      Beslist een aanrader om uw personeel eens goed te ‘verwennen’ tijdens een teambuilding of andere speciale gelegenheid!
    </div>
  }
]
