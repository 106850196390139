import React from 'react'
import styled from 'styled-components'
import { Title } from '../title/Title'

const Prices = styled.div`
  height: 100%;
  width: 100%;
  margin: 0;
  margin-bottom: 0;
  background-color: rgba(237, 237, 237, 0.8);
  display: flex;
  flex-direction: column;
  align-items: center;
`

const PricesWrapper = styled.div`
  width: 60%;
  @media (max-width: 700px) {
    width: 90%;
  }
`

const Price = styled.p`
  font-size: 21px;
`

export default () => <Prices id='Tarieven'>
  <PricesWrapper>
    <Title title='Tarieven' />
    <h2>Gesprek / Bachbloesemadvies</h2> <Price>55 euro</Price>
    <h2>Dieptemassage / tuina-acupressuur / qigong-behandeling</h2> <Price>55 euro per uur</Price>
    <h2>Stoelmassage</h2> <Price>30 euro per half uur</Price>
    <h2>Workshops / lezingen</h2> <Price>Prijzen op aanvraag.</Price>
    <br />
    <br />
    <br />
    <b>Neem vrijblijvend contact op indien u bijkomende informatie wenst.</b>
    <br />
    <br />
    <br />
  </PricesWrapper>
</Prices>
