import React from 'react'
import styled from 'styled-components'
import sunflowerImg from '../../images/Lezingen.jpg'
import { Title } from '../title/Title'

const BackgroundImg = styled.img`
  height: 60vh;
  width: 100%;
  object-fit: cover;
  position: fixed;
  z-index: -1;
`
const MassageWrapper = styled.div`
  height: 100%;
  width: 100%;
  padding-bottom: 500px;
`

const Padding = styled.div`
  position: absolute;
  background-color: rgba(237, 237, 237, 0.5);
  height: 50vh;
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
`
const WidthWrapper = styled.div`
  width: 85vw;
`

const Background = styled.div`
  width: 100%;
  position: absolute;
  margin-top: 50vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: white;
`

const Info = styled.p`
  width: 85vw;
`

const Subtitle = styled.h3``

export default class WorkshopsEnLezingen extends React.Component {
  render () {
    return (
      <MassageWrapper>
        <BackgroundImg src={sunflowerImg} alt='Massages' />
        <Padding>
          <WidthWrapper>
            <Title title='WORKSHOPS EN LEZINGEN' />
          </WidthWrapper>
        </Padding>
        <Background>
          <Info>
            <br />
            <br />
            Op regelmatige basis geef ik cursussen, workshops en lezingen over allerhande thema’s die te maken hebben met natuurlijke gezondheidszorg.
            <br />
            <br />
            Ook verzorg ik lezingen en workshops op maat van uw vereniging of bedrijf.
            <Subtitle>
            Een greep uit het aanbod <i>cursussen en workshops</i>:
            </Subtitle>
            -   Zelf aan de slag met bachbloesems
            <br />
            -   Hoe sta je gezond en energiek in het leven?
            <br />
            -   Zelf leren masseren
            <br />
            -   Qigong – Chinese bewegingsleer
            <br />
            <br />
            <Subtitle>
            Een greep uit het aanbod <i>lezingen</i>:
            </Subtitle>
            -        Kennismaking met de Bachbloesem-remedies
            <br />
            -        Eten met een gerust geweten
            <br />
            -        Natuurlijk gezond de winter tegemoet
            <br />
            -        Natuurlijk gezond de lente en zomer tegemoet
            <br />
            -        Herkennen en leren omgaan met stress – één van de hoofdoorzaken van talrijke gezondheidsproblemen
            <br />
            -        Hoe neem je je gezondheid in eigen handen?
            <br />
            <br />
            <br />
            <b>Geplande lezingen en cursussen worden aangekondigd via mijn facebookpagina “ARS VITAE”.</b>
            <br />
            <br />
            <br />
            <br />
            <br />
          </Info>
        </Background>
      </MassageWrapper>
    )
  }
}
