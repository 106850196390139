import React from 'react'
import { Title } from '../title/Title'
import styled from 'styled-components'
import profileImg from '../../images/OverMij.jpg'
import BachFoundation from '../../images/BachFoundation.jpg'

const AboutMeWrapper = styled.div`
`

const Text = styled.p`
  opacity: ${props => props.hide ? 0 : 1};
  visibility: ${props => props.hide ? 'hidden' : 'visible'};
  height: ${props => props.hide ? 0 : '100%'};
  margin: 0;
`

const ReadMore = styled.button`
  height: ${props => props.hide ? '100%' : 0};
  opacity: ${props => props.hide ? 1 : 0};
  ${props => !props.hide && { padding: 0 }};
`

const ProfileImg = styled.img`
  height: 23vh;
  width: 23vh;
  object-fit: cover;
  float: left;
  border-radius: 50%;
  padding: 0 10px 5px 0;
`

const ExtraImage = styled.img`
  height: ${props => props.hide ? 0 : '23vh'};
  object-fit: cover;
  float: left;
  margin: 0 10px 5px 0;
  border-radius: 10px;
`

export class AboutMe extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      hide: true
    }
  }
  render () {
    return (
      <AboutMeWrapper id='OverMij'>
        <Title title='Over mij' />
        <Text>
          <br />
          <ProfileImg src={profileImg} alt='Smiley face' />
          Als kind leefde ik in de natuur als een kleine ontdekkingsreiziger.
          <br />
          <br />
          De liefde voor planten en kruiden kreeg ik met de paplepel ingegeven door mijn moeder en grootmoeder. Ze beschikten over een uitgebreide ‘natuurlijke apotheek’ voor alledaagse kwaaltjes.
          <br />
          <br />
          Het voelde voor mij als vanzelfsprekend dat een lichaam met natuurlijke, eenvoudige middelen gezond kon worden en blijven.
          <br />
          <br />
          Ik verdiepte me in gezonde voeding en volgde een opleiding tot herborist.
          <br />
          <br />
          Nadien volgde ik nog een 4-jarige opleiding tot gezondheidsconsulente aan de Levensschool om me verder te bekwamen in de natuurlijke gezondheidszorg.
          <br />
          <br />
          Twijfels en vragen in verband met de opvoeding van mijn drie kinderen brachten me in contact met de bachbloesems … liefde op het eerste gezicht.
          <br />
          <br />
          Ik volgde de basisopleiding en na heel wat praktijkervaring in mijn eigen gezin en omgeving, heb ik de opleiding tot erkend Bach Consulente gevolgd en voltooid.
          <br />
          <br />
        </Text>
        <ReadMore hide={this.state.hide} onClick={() => this.setState({ hide: false })}>
          Lees meer
        </ReadMore>
        <Text hide={this.state.hide}>
          <ExtraImage hide={this.state.hide} src={BachFoundation} alt='Smiley face' />
          De combinatie van een gezin met een veeleisende job in het bedrijfsleven bezorgden me veel stress en kopzorgen.
          <br />
          <br />
          Zo kwamen Yoga, relaxatieoefeningen, meditatie …op mijn pad.
          <br />
          <br />
          Mijn interesse in het Oosterse gedachtegoed en filosofie was gewekt.
          <br />
          <br />
          Ik volgde een opleiding Mensbegeleiding en energetische massage (shiatsu) bij Eric Desmadryl en verdiepte me daarna nog meer in de Chinese geneeskunde. In 2018 reisde ik naar China en volgde daar qigongstage bij prof Du.

In 2019 behaalde ik mijn Postgraduaat massage (tuina en kindertuina) en bewegingsleer (Qigong) aan het OTCG.
          <br />
          <br />
          De opvoeding van mijn kinderen, meer dan 30 jaar ervaring in het bedrijfsleven, het doorleven van een burn-out, in combinatie met de vele opleidingen die ik volgde, maken me tot wie ik nu ben.
          <br />
          <br />
          Die kennis en ervaring wil ik graag delen in mijn praktijk om mensen gezonder, energieker en gelukkiger in het leven te laten staan, zowel op lichamelijk, mentaal-emotioneel als op geestelijk vlak. En dit steeds met aandacht voor zowel de Oosterse als Westerse zienswijze.
          <br />
          <br />
          Ik bied ondersteuning in het zoeken naar antwoorden op uw levensvragen, in uw eigen streven naar tevredenheid, stressbeheersing, balans, levensvreugde, … #levenskunst
          <br />
          <br />
          <span style={{ fontStyle: 'italic' }}>Wil u graag meer informatie over wat ik voor u zou kunnen betekenen? Neem dan gerust vrijblijvend contact op.</span>
        </Text>
      </AboutMeWrapper>
    )
  }
}
