import React from 'react'
import styled from 'styled-components'
import { Title } from '../title/Title'

const BloesemImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  @media (max-width: 700px) {
    width: 100vw;
  }
`

const MenuIcon = styled.img`
  position: absolute;
  top: 0;
  height: 30px;
  width: 30px;
  cursor: pointer;
  background-color: white;
`
const Image = styled.img`
  height: 40vh;
  width: 100%;
  object-fit: cover;
`

const Item = styled.div`
  height: 23vh;
  width: 23vh;
  margin-bottom: 2vh;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  border-radius: 50%;
`

const BloesemOverlay = styled.div`
  /* background-color: rgba(237, 237, 237, 0.7); */
  height: 60vh;
  width: 23vh;
  position: absolute;
  background: linear-gradient(rgba(237, 237, 237, 0.7) 50%, rgba(56, 89, 3, 1) 0) ;
  transform: translate3d(0, 0, 0);
  transition: transform 400ms ease-out;
  padding: 4px;
  ${props => props.isHovering &&
    `transform: translate3d(0, -35vh, 0);`
}
`

const BloesemTextOverlay = styled.div`
  height: 23vh;
  width: 23vh;
  position: absolute;
  font-size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  ${props => props.isHovering &&
    `color: white`
}
  transition: color 400ms ease-out;
`

const ItemOverlay = styled.div`
  height: 60vh;
  width: 40%;
  position: fixed;
  top: 0;
  overflow-y: scroll;
  background-color: white;
  transform: translate3d(0, ${props => props.isVisible ? '20vh' : '120vh'}, 0);
  transition: transform 400ms ease;
  z-index: 101;
  box-shadow: 0px 0px 10px #6b6b6b;
  @media (max-width: 700px) {
    height: 100vh;
    width: 100vw;
    transform: translate3d(0, ${props => props.isVisible ? '0' : '120vh'}, 0);
  }
`

const Info = styled.p`
  margin: 0;
  width: 80%;
  display: flex;
  padding-left: 30px;
  /* align-items: center; */
  flex-direction: column;
`

export class BloesemItem extends React.PureComponent {
  constructor (props) {
    super(props)

    this.state = {
      isHovering: false,
      isVisible: false
    }
  }
  render () {
    const {
      image,
      item
    } = this.props
    return (
      <>
        <Item
          onClick={() => this.setState({ isVisible: true })}
          onMouseEnter={() => this.setState({ isHovering: true })}
          onMouseLeave={() => this.setState({ isHovering: false })}>
          <BloesemImg src={image} />
          {/* <BloesemBg /> */}
          <BloesemOverlay isHovering={this.state.isHovering} />
          <BloesemTextOverlay isHovering={this.state.isHovering}>{item.name}</BloesemTextOverlay>
        </Item>
        <ItemOverlay
          isVisible={this.state.isVisible}>
          <MenuIcon onClick={() => this.setState({ isVisible: false })} src='https://img.icons8.com/material-rounded/50/000000/multiply.png' />
          <Image src={image} />
          <Info>
            <Title title={item.longName} />
            {item.info}
          </Info>
        </ItemOverlay>
      </>
    )
  }
}
